import React from "react"

import styled from "styled-components"

import { Link } from "gatsby"

import Img from "gatsby-image"

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  padding: 0rem;
  margin-top: 2rem;

  > div {
    transition: opacity 0.7s;
  }

  > div:hover {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  @media (min-width: 576px) {
    padding: 1rem;
    flex-basis: calc(50% - 2rem);
  }

  @media (min-width: 992px) {
    padding: 1rem;
    flex-basis: calc(50% - 2rem);
  }
`

const StyledImg = styled(Img)``

const Information = styled.div`
  margin-top: 1rem;
  color: black;
  text-align: center;

  a {
    text-decoration: none;
    font-weight: 300 !important;
    font-family: "Roboto";
    color: ${props => (props.textColor ? props.textColor : "black")};
  }
`

export default props => {
  let data = props.data

  return (
    <Tile className="cbd-tile">
      <div>
        <Link to={`/${data.uri}`}>
          {data.productImages.length > 0 ? (
            <StyledImg
              fluid={data.productImages[0].imageFile.childImageSharp.fluid}
            />
          ) : null}
        </Link>
        <Information textColor={props.textColor}>
          <h3>
            <Link to={`/${data.uri}`}>{data.title}</Link>
          </h3>
        </Information>
      </div>
    </Tile>
  )
}
