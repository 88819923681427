import React from "react"
import styled from "styled-components"

var moment = require("moment")

const Container = styled.div`
  max-width: 750px;
  margin: 0 auto;
`

const Title = styled.div`
  color: ${props => (props.textColor ? props.textColor : "white")};
  text-align: center;
  margin: 4rem 0;
  padding: 0 1.5rem;

  @media (min-width: 992px) {
    padding: 0;
  }
`

const Date = styled.div`
  color: ${props => (props.textColor ? props.textColor : "white")};
  text-align: center;
  margin: 4rem 0;
`

export default props => {
  return (
    <Container>
      <Title textColor={props.textColor}>
        <h1>{props.title}</h1>
      </Title>
      <Date className="date" textColor={props.textColor}>
        {/* {moment(props.date).format("MMMM D, YYYY")} */}
      </Date>
    </Container>
  )
}
