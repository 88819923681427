import React from "react"
import styled from "styled-components"

const Container = styled.div`
  max-width: 750px;
  margin: 0 auto;
`

const Text = styled.div`
  color: ${props => (props.textColor ? props.textColor : "#272727")};
  text-align: left;
  margin: 4rem 0;
  padding: 0 1.5rem;

  @media (min-width: 992px) {
    padding: 0;
  }
`

export default props => {
  return (
    <Container>
      <Text
        dangerouslySetInnerHTML={{ __html: props.data.text }}
        textColor={props.textColor}
      />
    </Container>
  )
}
