import React from "react"
import { Image } from "../image"

import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  margin: 5rem auto;

  @media (min-width: 576px) {
    width: 80%;
  }
`

export default props => {
  return (
    <Container>
      <Image src={props.data.image[0]} />
    </Container>
  )
}
