import React from "react"

import { Image } from "../image"

import styled from "styled-components"

const Container = styled.div`
  overflow: hidden;

  video {
    width: 100%
  }
`

const Spacer = styled.div`
  height: 0.01rem;

  @media (min-width: 576px) {
    height: 5rem;
  }
`

export default props => {
  let display = false
  if (props.data.video[0] || props.data.image[0]) display = true

  {
    return display ? (
      <Container>
        {props.data.video[0] ? (
          <video autoPlay muted playsInline loop>
            <source src={props.data.video[0].url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <Image src={props.data.image[0]} />
        )}
      </Container>
    ) : (
      <Spacer />
    )
  }
}
