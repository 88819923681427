import React, {useEffect} from "react"


export default props => {

    useEffect(()=>{
        const script = document.createElement("script");
        script.src = props.data.script;
        script.async = true;

        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        }
    },[])
  return null
}
