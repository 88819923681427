import React, { useEffect, useRef, useState } from "react"
import { Image } from "../image"

import Flickity from "flickity"
import "../../../node_modules/flickity/css/flickity.css"

import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;
  margin: 5rem 0;

  .carousel-cell {
    height: 100%;
    width: auto;
  }

  .flickity-viewport {
    height: 100%;
  }

  .gatsby-image-wrapper {
    // height: 100%;
  }

  @media (min-width: 576px) {
    flex-direction: row;
  }

  img {
    height: auto !important;
  }
`

const Left = styled.div`
  flex-basis: 60%;
  order: -1;
  height: fit-content;

  @media (min-width: 576px) {
    order: ${props => (props.leftAlign ? -1 : 1)};
  }

  .flickity-button {
    display: ${props => (props.showArrows ? "block" : "none")};
  }
`

const Right = styled.div`
  flex-basis: 40%;
  box-sizing: border-box;
  padding: 2.5rem 1.5rem 0 1.5rem;

  .button {
    width: 100% !important;
    box-sizing: border-box;
  }

  @media (max-width: 576px) {
    padding-bottom: 4rem;
    padding-top: 3rem;
  }

  @media (min-width: 576px) {
    .button {
      width: fit-content !important;
    }

    padding: ${props =>
      props.leftAlign ? "0 3.625rem 0 2rem" : "0 2rem 0 3.625rem"};
  }
`

const Title = styled.div`
  color: ${props => (props.textColor ? props.textColor : "white")};
`

const Text = styled.div`
  color: ${props => (props.textColor ? props.textColor : "white")};
  margin: 2rem 0;
`

const Button = styled.a`
  min-width: 14rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  display: flex;
  text-decoration: none;

  color: ${props => (props.textColor ? props.textColor : "white")} !important;

  border-color: ${props =>
    props.textColor ? props.textColor : "white"} !important;

  :hover {
    color: ${props =>
      props.backgroundColor ? props.backgroundColor : "white"} !important;

    background-color: ${props =>
      props.textColor ? props.textColor : "white"} !important;
  }
`

const Buttons = styled.div``

export default props => {
  let flickity
  let [windowWidth, setWindowWidth] = useState(0)
  let [ratio, setRatio] = useState(0)
  let gallery = useRef()

  useEffect(() => {
    setWindowWidth(window.innerWidth)

    if (windowWidth <= 576) {
      setRatio(1)
    } else {
      setRatio(0.6)
    }

    flickity = new Flickity(gallery.current, {
      // options, defaults listed
      imagesLoaded: true,

      accessibility: true,
      // enable keyboard navigation, pressing left & right keys
      arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 65,
        y2: 45,
        x3: 20,
      },

      adaptiveHeight: false,
      // set carousel height to the selected slide

      autoPlay: false,
      // advances to the next cell
      // if true, default is 3 seconds
      // or set time between advances in milliseconds
      // i.e. `autoPlay: 1000` will advance every 1 second

      cellAlign: "center",
      // alignment of cells, 'center', 'left', or 'right'
      // or a decimal 0-1, 0 is beginning (left) of container, 1 is end (right)

      cellSelector: undefined,
      // specify selector for cell elements

      contain: false,
      // will contain cells to container
      // so no excess scroll at beginning or end
      // has no effect if wrapAround is enabled

      draggable: ">1",
      // enables dragging & flicking
      // if at least 2 cells

      dragThreshold: 3,
      // number of pixels a user must scroll horizontally to start dragging
      // increase to allow more room for vertical scroll for touch devices

      freeScroll: false,
      // enables content to be freely scrolled and flicked
      // without aligning cells

      friction: 0.2,
      // smaller number = easier to flick farther

      groupCells: false,
      // group cells together in slides

      initialIndex: 0,
      // zero-based index of the initial selected cell

      lazyLoad: true,
      // enable lazy-loading images
      // set img data-flickity-lazyload="src.jpg"
      // set to number to load images adjacent cells

      percentPosition: true,
      // sets positioning in percent values, rather than pixels
      // Enable if items have percent widths
      // Disable if items have pixel widths, like images

      prevNextButtons: true,
      // creates and enables buttons to click to previous & next cells

      pageDots: false,
      // create and enable page dots

      resize: true,
      // listens to window resize events to adjust size & positions

      rightToLeft: false,
      // enables right-to-left layout

      setGallerySize: true,
      // sets the height of gallery
      // disable if gallery already has height set with CSS

      watchCSS: false,
      // watches the content of :after of the element
      // activates if #element:after { content: 'flickity' }

      wrapAround: false,
      // at end of cells, wraps-around to first for infinite scrolling
    })

    flickity.resize()
  })

  useEffect(() => {
    function resizeFlickity() {
      setWindowWidth(window.innerWidth)

      if (windowWidth <= 576) {
        setRatio(1)
      } else {
        setRatio(0.6)
      }
      flickity.resize()
    }

    window.addEventListener("resize", resizeFlickity)

    return () => {
      window.removeEventListener("resize", resizeFlickity)
    }
  }, [])

  let buttonOneActive = false
  let buttonTwoActive = false

  if (props.data.buttonOneText) {
    buttonOneActive = true
  }

  if (props.data.buttonTwoText) {
    buttonTwoActive = true
  }

  return (
    <Container>
      <Left
        ref={gallery}
        leftAlign={props.data.leftAlign}
        showArrows={props.data.images.length > 1}
      >
        {props.data.images.map((item, index) => (
          <Image
            style={{
              height:
                (1 /
                  (props.data.images[index].imageFile
                    ? props.data.images[index].imageFile.childImageSharp.fluid
                        .aspectRatio
                    : props.data.images[index].height /
                      props.data.images[index].width)) *
                windowWidth *
                ratio,
              width: windowWidth * ratio,
            }}
            className="carousel-cell"
            src={props.data.images[index]}
          />
        ))}
      </Left>
      <Right leftAlign={props.data.leftAlign}>
        <Title textColor={props.textColor}>
          <h2>{props.data.titleField}</h2>
        </Title>
        <Text
          textColor={props.textColor}
          dangerouslySetInnerHTML={{ __html: props.data.textField }}
        />
        <Buttons>
          {buttonOneActive ? (
            <Button
              className="button"
              textColor={props.textColor}
              backgroundColor={props.backgroundColor}
              href={props.data.buttonOneLink}
              target="_blank"
            >
              {props.data.buttonOneText}
            </Button>
          ) : null}
          {buttonTwoActive ? (
            <Button
              className="button"
              textColor={props.textColor}
              backgroundColor={props.backgroundColor}
              href={props.data.buttonTwoLink}
              target="_blank"
            >
              {props.data.buttonTwoText}
            </Button>
          ) : null}
        </Buttons>
      </Right>
    </Container>
  )
}
