import React, { useRef, useEffect } from "react"
import { Image } from "../image"

import styled from "styled-components"

const Container = styled.div`
  position: relative;
  margin: 5rem 0;
  overflow: hidden;
  padding-bottom: 0.1rem;

  video {
    width: 100%;
  }
`

const TextOverlay = styled.div`
  position: absolute;
  z-index: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 1.5rem;
  box-sizing: border-box;

  h2 {
    max-width: 1098px;
    display: block;
    font-weight: 300 !important;
    color: ${props => (props.textColor ? props.textColor : "white")};
    cursor: default;
  }
`

export default props => {
  let containerRef = useRef()
  let textRef = useRef()

  useEffect(() => {
    if (props.text) {
      let height = textRef.current.getBoundingClientRect().height

      containerRef.current.style.height = `${height + 42}px`
    }
  }, [])

  return (
    <Container ref={containerRef} className="container">
      <TextOverlay textColor={props.textColor}>
        <h2 ref={textRef}>{props.text}</h2>
      </TextOverlay>
      {props.data.video[0] ? (
        <video autoPlay muted playsInline loop>
          <source src={props.data.video[0].url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <Image src={props.data.image[0]} />
      )}
    </Container>
  )
}
