import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

const Button = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  margin: 5rem auto;
  line-height: 1.1;

  color: ${props => (props.textColor ? props.textColor : "#eeede9")} !important;

  border-color: ${props =>
    props.textColor ? props.textColor : "#eeede9"} !important;

  :hover {
    color: ${props =>
      props.backgroundColor ? props.backgroundColor : "#eeede9"} !important;

    background-color: ${props =>
      props.textColor ? props.textColor : "#eeede9"} !important;
  }
`

export default props => {
  return (
    <Button
      className="button"
      href={props.data.linkField}
      textColor={props.textColor}
      backgroundColor={props.backgroundColor}
      target="_blank"
    >
      {props.data.linkText}
    </Button>
  )
}
