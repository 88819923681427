import React, { useEffect } from "react"
import styled from "styled-components"

import ScrollReveal from "scrollreveal"

import CBDTile from "./cbd-tile"

const Spacer = styled.div`
  padding-top: 6rem;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "white"};
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 1.5rem 6rem 1.5rem;
  background-color: ${props =>
      props.backgroundColor ? props.backgroundColor : "white"}
    @media (min-width: 576px) {
    padding: 0 3rem 6rem 3rem;
  }
`

export default props => {
  let data = props.data

  useEffect(() => {
    ScrollReveal({
      //   reset: true,
      delay: 1,
      distance: "30px",
    }).reveal(".cbd-tile")

    return () => {
      ScrollReveal().destroy()
    }
  }, [])
  return (
    <>
      {/* <Spacer /> */}
      <Container backgroundColor={props.backgroundColor}>
        {data.map(data => (
          <CBDTile data={data} textColor={props.textColor} />
        ))}
      </Container>
    </>
  )
}
