import React, { useEffect, useState, useRef, useCallback } from "react"
import styled from "styled-components"
import posed from "react-pose"
import Plyr from "plyr"

import { Link } from "./index"

import PlayImg from "../../icons/Play.svg"

import handleViewport from "react-in-viewport"

import _ from "lodash"

require("intersection-observer")

const Container = styled.div`
  width: calc(100% - 3rem) !important;

  @media (min-width: 576px) {
    width: 80% !important;
  }

  position: relative;
  margin: 5rem auto;

  .plyr__controls {
    background: none !important;
    justify-content: center !important;
  }

  .plyr__control.plyr__tab-focus {
    box-shadow: none;
  }

  .plyr--video .plyr__control.plyr__tab-focus,
  .plyr--video .plyr__control:hover,
  .plyr--video .plyr__control[aria-expanded="true"] {
    background: none;
  }

  .plyr__volume input[type="range"] {
    display: none;
  }

  .plyr__volume {
    max-width: 40px;
  }

  .plyr--full-ui input[type="range"] {
    color: white;
  }

  .plyr__controls__item.plyr__menu {
    display: none;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__controls .plyr__controls__item.plyr__time {
    display: none;
  }

  .plyr__controls .plyr__controls__item:first-child {
    margin-left: 0;
    margin-right: 0;
  }

  .plyr__controls__item.plyr__progress__container {
    max-width: 400px;
  }

  input[type="range"]::-webkit-slider-thumb {
    opacity: 0;
  }

  input[type="range"]::-moz-range-thumb {
    opacity: 0;
  }

  input[type="range"]::-ms-thumb {
    opacity: 0;
  }

  .plyr__progress__buffer {
    border-radius: 0;
    display: none;
  }

  .plyr > button {
    display: none !important;
  }

  .plyr.plyr--stopped .plyr__controls {
    display: none;
  }
`

let duration = 500

const preOverlay = posed.div({
  show: {
    opacity: 1,
    filter: "blur(0px)",
    applyAtStart: {
      display: "flex",
    },
    transition: {
      duration: duration,
      ease: "easeInOut",
    },
  },
  hide: {
    opacity: 0,
    filter: "blur(25px)",
    applyAtEnd: {
      display: "none",
    },
    transition: {
      duration: duration,
      ease: "easeInOut",
    },
  },
})

const Overlay = styled(preOverlay)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #eeede9;
  cursor: pointer;
`

const PreViewPortBlock = styled.div`
  height: 50vh;
  width: 50vw;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  pointer-events: none;
`

const StyledImg = styled.img`
  height: 32px;
`

const Block = props => {
  const { inViewport, forwardedRef } = props

  return <PreViewPortBlock ref={forwardedRef} />
}

const ViewportBlock = handleViewport(
  Block,
  { threshold: 0.8 } /** config: {} **/
)

const Video = styled.div``

let player = []

export default props => {
  let videoRef = useRef()
  let [isPlaying, setIsPlaying] = useState(false)
  let id = props.id

  useEffect(() => {
    let newPlayer = new Plyr(`#player-${id}`, {
      fullscreen: { enabled: true, fallback: true, iosNative: true },
    })

    player.push(newPlayer)

    return () => {
      // player[id].destroy()
      player = []
    }
  }, [])

  const click = e => {
    player[id].play()
    setIsPlaying(!isPlaying)
  }

  let linkData = {
    linkText: props.data.linkText,
    linkField: props.data.linkField,
  }

  let buttonOneActive = false

  if (linkData.linkText && linkData.linkField) {
    buttonOneActive = true
  }

  return (
    <>
      <Container>
        {/* <ViewportBlock
        onEnterViewport={() => toggleTimer("start")}
        onLeaveViewport={() => toggleTimer("stop")}
      /> */}
        <Overlay onClick={e => click(e)} pose={isPlaying ? "hide" : "show"}>
          {props.data.title ? (
            <h1>{props.data.title}</h1>
          ) : (
            <StyledImg src={PlayImg} />
          )}
        </Overlay>
        <Video
          className="plyr__video-embed"
          id={`player-${id}`}
          ref={videoRef}
          dangerouslySetInnerHTML={{
            __html:
              props.data.videoPlatform === "youtube"
                ? `<iframe src="https://www.youtube.com/embed/${props.data.videoEmbedId}?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1" allowfullscreen allowtransparency allow="autoplay" ></iframe>`
                : `<iframe src="https://player.vimeo.com/video/${props.data.videoEmbedId}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=false&amp;transparent=0&amp;gesture=media" allowfullscreen allowtransparency allow="autoplay" ></iframe>`,
          }}
        />
      </Container>
      {buttonOneActive ? (
        <Link
          data={linkData}
          backgroundColor={props.backgroundColor}
          textColor={props.textColor}
        />
      ) : null}
    </>
  )
}
