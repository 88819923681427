import React from "react"
import styled from "styled-components"

var moment = require("moment")

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 1.5rem;
  box-sizing: border-box;

  @media (min-width: 576px) {
    padding: 0 4rem;
  }
`

const Title = styled.div`
  color: ${props => (props.textColor ? props.textColor : "white")};
  text-align: left;
  margin: 4rem 0;
  width: 100%;

  h1 {
    font-weight: 300 !important;
  }

  @media (min-width: 576px) {
    width: 50%;
  }
`

const Date = styled.div`
  color: ${props => (props.textColor ? props.textColor : "white")};
  text-align: left;
  text-transform: uppercase;
  margin: 0rem 0;
  width: 100%;

  @media (min-width: 576px) {
    width: 50%;
  }
`

export default props => {
  return (
    <Container>
      <Title textColor={props.textColor}>
        <h1>{props.title}</h1>
      </Title>
      <Date className="date" textColor={props.textColor}>
        {moment(props.date).format("MMMM D, YYYY")}
      </Date>
    </Container>
  )
}
