import React from "react"
import styled from "styled-components"

import { Image } from "../image"

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4rem 0;
  padding: 0 1.5rem;
  box-sizing: border-box;

  @media (min-width: 992px) {
    padding: 0 4rem;
    flex-direction: row;
  }

  img {
    height: auto !important;
  }
`
const StyledImg = styled(Image)`
  margin-bottom: 4rem;

  :last-child {
    margin-bottom: 0;
  }
`

const Text = styled.div`
  color: ${props => (props.textColor ? props.textColor : "white")};
  text-align: left;
  margin: 0rem 0;
  padding-right: 0rem;

  @media (min-width: 576px) {
    padding-right: 4rem;
  }
`

const Left = styled.div`
  flex-basis: 100%;
  padding: 0rem;
  box-sizing: border-box;

  @media (min-width: 992px) {
    flex-basis: 50%;
    padding: 0 8rem;
  }
`

const Right = styled.div`
  flex-basis: 100%;
  order: -1;
  margin-bottom: 3rem;

  @media (min-width: 992px) {
    order: 0;
    flex-basis: 50%;
    margin-bottom: 0rem;
  }
`

export default props => {
  return (
    <Container>
      <Left>
        {props.data.images.length > 0
          ? props.data.images.map((item, index) => <StyledImg src={item} />)
          : null}
      </Left>
      <Right>
        <Text
          dangerouslySetInnerHTML={{ __html: props.data.text }}
          textColor={props.textColor}
        />
      </Right>
    </Container>
  )
}
