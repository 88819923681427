import React, { useState, useEffect, useContext } from "react"
import SiteContext from "../context/SiteContext"

import styled from "styled-components"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Slider from "../components/slices/slider"

import {
  Header,
  TitleNews,
  Text,
  Link,
  Video,
  FullWidthMedia,
  Image,
  MediaSlice,
} from "../components/slices"

import { CBDGrid } from "../components/cbd"

const axios = require("axios")

const Container = styled.div`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "white"};

  padding-bottom: 0.001rem;

  .cbd-text p {
    text-align: center;
    font-family: "EB Garamond";

    font-size: 1.25rem;
    line-height: 1.75rem;

    @media (min-width: 576px) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`

const renderSlice = (slice, backgroundColor, textColor, videoId) => {
  switch (slice.__typename || slice.typeHandle) {
    case "Craft_slicesNews_text_BlockType":
    case "text":
      return (
        <div className="cbd-text">
          <Text
            data={slice}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
        </div>
      )
      break
    case "Craft_slicesNews_link_BlockType":
    case "link":
      return (
        <Link
          data={slice}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      )
      break
    case "Craft_slicesNews_video_BlockType":
    case "video":
      return (
        <Video
          data={slice}
          backgroundColor={backgroundColor}
          textColor={textColor}
          id={videoId}
        />
      )
      break
    case "Craft_slicesNews_fullWidthMedia_BlockType":
    case "fullWidthMedia":
      return (
        <FullWidthMedia
          data={slice}
          text={slice.textField}
          textColor={textColor}
        />
      )
      break
    case "Craft_slicesNews_image_BlockType":
    case "image":
      return <Image data={slice} />
      break
    case "Craft_slicesNews_mediaSlice_BlockType":
    case "mediaSlice":
      return (
        <MediaSlice
          data={slice}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      )
      break
    default:
      return null
  }
}

export default props => {
  let [data, setData] = useState(props.data.craft.cbdPage[0])
  let context = useContext(SiteContext)
  let [colors, setColors] = useState({
    backgroundColor: props.data.craft.cbdPage[0]?.backgroundColor
      ? props.data.craft.cbdPage[0].backgroundColor
      : "white",
    textColor: props.data.craft.cbdPage[0]?.textColor
      ? props.data.craft.cbdPage[0].textColor
      : "black",
  })

  // if (data !== (null || undefined)) {
  //   setColors({
  //     backgroundColor: props.data.craft.cbdPage[0].backgroundColor
  //       ? props.data.craft.cbdPage[0].backgroundColor
  //       : "white",
  //     textColor: props.data.craft.cbdPage[0].textColor
  //       ? props.data.craft.cbdPage[0].textColor
  //       : "black",
  //   })
  // }

  let dataProducts = props.data.craft.cbdProducts

  let videoId = -1

  useEffect(() => {
    if (data !== (null || undefined)) {
      context.changeColor(colors.textColor)
    }
  }, [])

  useEffect(() => {
    let hashes = window.location.search
      .slice(window.location.search.indexOf("?") + 1)
      .split("&")
    let params = {}
    hashes.map(hash => {
      let [key, val] = hash.split("=")
      params[key] = decodeURIComponent(val)
    })

    if (params.token) {
      axios({
        url: "https://hbfff.frb.io/api",
        method: "post",
        params: {
          token: `${params.token}`,
          "x-craft-live-preview": params["x-craft-live-preview"],
        },
        data: {
          query: `
          query {
            entries(section: ["CBDpage"]) {
              ... on cbdPage_cbdPage_Entry {
                slideDuration
                backgroundColor
                textColor
                carouselSlides {
                  ... on carouselSlides_slide_BlockType {
                    titleField
                    ctaText
                    ctaLink
                    image {
                      url
                    }
                    video {
                      url
                    }
                  }
                }
                slicesNews {
                  ... on slicesNews_text_BlockType {
                    typeHandle
                    text
                  }
                  ... on slicesNews_link_BlockType {
                    typeHandle
                    linkText
                    linkField
                  }
                  ... on slicesNews_video_BlockType {
                    typeHandle
                    videoEmbedId
                    linkText
                    linkField
                    videoPlatform
                  }
                  ... on slicesNews_fullWidthMedia_BlockType {
                    typeHandle
                    textField
                    video {
                      url
                    }
                    image {
                      url
                    }
                  }
                  ... on slicesNews_image_BlockType {
                    typeHandle
                    image {
                      url
                    }
                  }
                  ... on slicesNews_mediaSlice_BlockType {
                    typeHandle
                    images {
                      url
                    }
                    titleField
                    textField
                    leftAlign
                    buttonOneText
                    buttonTwoText
                    buttonOneLink
                    buttonTwoLink
                  }
                }
              }
            }
            }
            `,
        },
        headers: {
          Authorization: `Bearer EQgYRtC5gHijqoBTQ-uQRmykBleFdtDe`,
        },
      }).then(result => {
        setData(null)
        setData(result.data.data.entries[0])

        context.changeColor(result.data.data.entries[0].textColor)

        setColors({
          backgroundColor: result.data.data.entries[0].backgroundColor,
          textColor: result.data.data.entries[0].textColor,
        })
      })
    }
  }, [])

  return data !== (null || undefined) ? (
    <Layout backgroundColor={data.backgroundColor} textColor={data.textColor}>
      <SEO title={data.title} />
      <Container backgroundColor={data.backgroundColor}>
        <Slider data={data} textColor={data.textColor} isIndex={false} />
        {data.slicesNews.map((slice, index) => {
          if (
            slice.__typename === "Craft_slicesNews_video_BlockType" ||
            slice.typeHandle === "video"
          ) {
            videoId += 1
          }

          if (index === data.slicesNews.length - 1) {
            return [
              <CBDGrid
                data={dataProducts}
                backgroundColor={data.backgroundColor}
                textColor={data.textColor}
              />,
              renderSlice(slice, data.backgroundColor, data.textColor, videoId),
            ]
          }

          return renderSlice(
            slice,
            data.backgroundColor,
            data.textColor,
            videoId
          )
        })}
      </Container>
    </Layout>
  ) : null
}

export const query = graphql`
  query {
    craft {
      cbdPage: entries(section: ["CBDpage"]) {
        ... on Craft_cbdPage_cbdPage_Entry {
          slideDuration
          backgroundColor
          textColor
          title
          carouselSlides {
            ... on Craft_carouselSlides_slide_BlockType {
              titleField
              ctaText
              ctaLink
              image {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              video {
                url
              }
            }
          }
          slicesNews {
            ... on Craft_slicesNews_text_BlockType {
              text
            }
            ... on Craft_slicesNews_link_BlockType {
              linkText
              linkField
            }
            ... on Craft_slicesNews_video_BlockType {
              videoEmbedId
              linkText
              linkField
              videoPlatform
            }
            ... on Craft_slicesNews_fullWidthMedia_BlockType {
              textField
              video {
                url
              }
              image {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on Craft_slicesNews_image_BlockType {
              image {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on Craft_slicesNews_mediaSlice_BlockType {
              images {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              titleField
              textField
              leftAlign
              buttonOneText
              buttonTwoText
              buttonOneLink
              buttonTwoLink
            }
          }
        }
      }
      cbdProducts: entries(section: ["cbdProduct"]) {
        ... on Craft_cbdProduct_cbdProduct_Entry {
          id
          postDate
          title
          uri
          slug
          productImages {
            ... on Craft_images_Asset {
              url
              imageFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
